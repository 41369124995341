import React from 'react'
import { View, Button as RNButton, StyleSheet } from 'react-native'
import { colors } from '../theme'

export default function Button({ secondary, children, ...props }) {
  return (
    <View style={styles.button}>
      <RNButton
        title={children}
        color={secondary ? colors.secondary : colors.primary}
        {...props}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    overflow: 'hidden',
    borderRadius: 10,
    marginTop: 10,
  },
})
