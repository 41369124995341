import React, { useState, useContext } from 'react'
import { Text, TextInput, StyleSheet, LogBox } from 'react-native'
import UserContext from '../UserContext'
import Container from '../components/Container'
import Loading from '../components/Loading'
import Card from '../components/Card'
import Button from '../components/Button'
import api, { ApiError } from '../api'

const otpLength = 6

export default function Otp({ navigation, route }) {
  const user = useContext(UserContext)
  const [otp, setOtp] = useState('')
  const [fetching, setFetching] = useState(false)

  async function handlePress() {
    setFetching(true)
    try {
      const data = await api.authenticate(
        route.params.emailAddress,
        route.params.firstName,
        route.params.lastName,
        route.params.phoneId, 
        otp
      )
      user.update(data)
      navigation.navigate('Home')
    } catch (e) {
      if (e instanceof ApiError && e.type === 'invalid_otp') {
        alert('Le code entré n\'est pas correct, veuillez réessayer.')
      } else {
        alert('Une erreur inconnue s\'est produite, veuillez vérifier votre connexion à internet.')
      }
    }
    setFetching(false)
  }

  // TODO? - refactor: custom OtpInput component?
  return (
    <Container centered>
      <Loading loaded={!fetching}>
        <Card title="Code de connexion">
          <Text>
            Veuillez consulter votre boîte mail, vous devriez y trouver
            un code de {otpLength} caractères à fournir ci-dessous.
          </Text>
          <TextInput
            style={styles.otpInput}
            value={otp}
            onChangeText={setOtp}
            maxLength={otpLength}
            autoFocus
            autoCapitalize="characters"
            autoCompleteType="off"
            autoCorrect={false}
            textContentType="oneTimeCode"
          />
          <Button onPress={handlePress} disabled={otp.length < otpLength}>
            Soumettre
          </Button>
        </Card>
      </Loading>
    </Container>
  )
}

const styles = StyleSheet.create({
  otpInput: {
    fontWeight: 'bold',
    backgroundColor: '#dddddd',
    alignSelf: 'center',
    textAlign: 'center',
    width: 130,
    height: 40,
    marginTop: 10,
  },
})
