const host = 'https://api.folktrace.be/v1'

export class ApiError extends Error {
  constructor(message, domains) {
    const type = message ?? 'unknown'
    super(type)
    this.name = this.constructor.name
    this.type = type
    this.domains = domains
  }
}

export default {

  async register(emailAddress, phoneId) {
    const response = await fetch(`${host}/register/`, {
      method: 'POST',
      body: JSON.stringify({
        email_address: emailAddress,
        phone_id: phoneId,
      }),
    })

    if (!response.ok) {
      const data = await response.json()
      // TODO - Sync with API
      if (data instanceof Array) {
        throw new ApiError('unauthorized_domain', data)
      } else {
        throw new ApiError(data?.error)
      }
    }
  },

  async authenticate(emailAddress, firstName, lastName, phoneId, otp) {
    const response = await fetch(`${host}/auth/`, {
      method: 'POST',
      body: JSON.stringify({
        email_address: emailAddress,
        first_name: firstName,
        last_name: lastName,
        phone_id: phoneId,
        otp,
      }),
    })

    const data = await response.json()
    if (response.ok) {
      return {
        uuid: data.uuid,
        token: data.token,
        isStaff: data.is_staff,
      }
    } else {
      throw new ApiError(data?.error)
    }
  },

  async logout(token) {
    const response = await fetch(`${host}/logout/`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Token ${token}`
      }),
    })
    if (!response.ok) {
      throw new ApiError(data?.error)
    }
  },

  async affluence() {
    const response = await fetch(`${host}/affluence/`)
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      throw new ApiError(data?.error)
    }
  },

  async entrance(token, type, uuid) {
    const response = await fetch(`${host}/entrance/`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Token ${token}`
      }),
      body: JSON.stringify({
        type,
        uuid,
      }),
    })

    const data = await response.json()
    if (response.ok) {
      return data.type
    } else {
      throw new ApiError(data?.error)
    }
  },
}
