import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { faSignInAlt, faSignOutAlt, faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

export default function Header({
  connected,
  onConnect,
  onDisconnect,
  onAbout,
}) {
  return (
    <View style={styles.bar}>
      <TouchableOpacity onPress={connected ? onDisconnect : onConnect}>
        <View style={styles.circle}>
          <FontAwesomeIcon icon={connected ? faSignOutAlt : faSignInAlt} />
        </View>
      </TouchableOpacity>
      <Text style={styles.title}>
        Folktrace
      </Text>
      <TouchableOpacity onPress={onAbout}>
        <View style={styles.circle}>
          <FontAwesomeIcon icon={faInfo} />
        </View>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  bar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  title: {
    fontSize: 25,
    fontWeight: '300',
  },
  circle: {
    overflow: 'hidden',
    borderRadius: 40,
    width: 45,
    height: 45,
    padding: 10,
  },
})
