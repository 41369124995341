import React from 'react'
import { ScrollView, Text, StyleSheet, Linking, TouchableOpacity } from 'react-native'
import Container from '../components/Container'
import Card from '../components/Card'
import Button from '../components/Button'
import { colors } from '../theme'

export default function About({ navigation, route }) {
  const loadInBrowser = () => {
    Linking
      .openURL("https://api.folktrace.be/privacy/")
      .catch(err => console.error("Couldn't load page", err))
  }

  return (
    <Container>
      <ScrollView style={styles.box}>
        <Card title="Contrat d'utilisation">
          {/* TODO - iframe */}
          <Text>
            Le contrat d'utilisation est disponible à l'adresse suivante :
          </Text>
          <TouchableOpacity onPress={() => loadInBrowser()} style={styles.center}>
            <Text style={[styles.highlight, styles.bold]}>api.folktrace.be/privacy</Text>
          </TouchableOpacity>
        </Card>
        {route.params.approvable && <Card>
          <Text style={styles.bold}>
            En continuant, vous reconnaissez avoir pris connaissance de ce contrat et acceptez que
            ces données soient récoltées et enregistrées comme décrit ci-dessus.
          </Text>
          <Button onPress={() => navigation.navigate('Login')}>
            Lu et approuvé
          </Button>
        </Card>}
      </ScrollView>
      <Button secondary onPress={() => navigation.goBack()}>
        Retour
      </Button>
    </Container >
  )
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  highlight: {
    textAlign: 'center',
    color: colors.primary,
  },
  center: {
    margin: 'auto',
    padding: 10,
  },
})
