import React, { useContext } from 'react'
import { ScrollView, Platform } from 'react-native'
import Alert from '../Alert'
import UserContext, { initialUser } from '../UserContext'
import Container from '../components/Container'
import Header from '../components/Header'
import Qr from '../components/Qr'
import Affluence from '../components/Affluence'
import Button from '../components/Button'
import api from '../api'

export default function Home({ navigation }) {
  const user = useContext(UserContext)
  const showScanner = Platform.OS !== 'web' && user.isStaff

  async function handleLogout() {
    try {
      await api.logout(user.token)
    } catch {
      alert('Une erreur inconnue s\'est produite, veuillez vérifier votre connexion à internet.')
    }
    user.update(initialUser)
  }

  function toLogin() {
    navigation.navigate('Contract', { approvable: true })
  }

  function toLogout() {
    let warning = showScanner
      ? 'Attention, en vous déconnectant, vous perdrez le droit de scanner des codes QR. Pour le ré-activer, vous devrez prendre contact à l\'adresse "folktrace@unamur.be". '
      : ''
    Alert.alert(
      'Déconnexion',
      warning + 'Souhaitez-vous vraiment vous déconnecter ?',
      [
        { text: 'Non', style: 'cancel' },
        { text: 'Oui', style: 'default', onPress: handleLogout },
      ]
    )
  }

  function toAbout() {
    navigation.navigate('About')
  }

  function toScanner() {
    navigation.navigate('Scanner')
  }

  return (
    <Container>
      <Header
        connected={user.uuid}
        onConnect={toLogin}
        onDisconnect={toLogout}
        onAbout={toAbout}
      />
      <ScrollView>
        <Qr uuid={user.uuid} onConnect={toLogin} />
        <Affluence />
      </ScrollView>
      {showScanner && <Button onPress={toScanner}>
        Ouvrir le scanner
      </Button>}
    </Container>
  )
}
