import React, { useRef, forwardRef } from 'react'
import { View, Text, StyleSheet, Platform } from 'react-native'
import QrCode from 'react-native-qrcode-svg'
import Card from './Card'
import Button from './Button'

const showPrintButton = Platform.OS === 'web'

export default function Qr({ uuid, onConnect }) {
  const printable = useRef(null)

  return (
    <Card title="Votre code QR">
      {
        uuid
          ? <>
            <PrintableView ref={printable}>
              <QrCode value={'u:' + uuid} size={200} />
            </PrintableView>
            {showPrintButton && <>
              <Text>Si vous utilisez un ordinateur public, n'oubliez pas de vous déconnecter quand vous avez terminé.</Text>
              <Button onPress={() => printElement(printable)}>
                Imprimer le code
              </Button>
            </>}
          </>
          : <>
            <Text>Veuillez vous connecter pour avoir accès à votre code.</Text>
            <Button onPress={onConnect}>Se connecter</Button>
          </>
      }
    </Card>
  )
}

const PrintableView = forwardRef(({ children }, ref) => {
  return showPrintButton
    ? <div ref={ref} style={styles.qr}>
      {children}
    </div>
    : <View style={styles.qr}>
      {children}
    </View>
})

function printElement(ref) {
  const win = open('', '', 'width=800,height=600')
  win.document.write(ref.current.innerHTML)
  win.document.close()
  win.focus()
  win.print()
  win.close()
}

const styles = showPrintButton
  ? {
    qr: {
      alignSelf: 'center',
      backgroundColor: '#ffffff',
      borderColor: '#000000',
      borderStyle: 'solid',
      borderWidth: 5,
      borderRadius: 10,
      padding: 20,
      marginBottom: 10,
    },
  }
  : StyleSheet.create({
    qr: {
      alignSelf: 'center',
      backgroundColor: '#ffffff',
      borderColor: '#000000',
      borderWidth: 5,
      borderRadius: 10,
      padding: 20,
    },
  })
