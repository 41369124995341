import { Alert, Platform } from 'react-native'

// Alert is not working on web.
// polyfill adapted from https://github.com/necolas/react-native-web/issues/1026#issuecomment-679102691

function alertWeb(_, description, options) {
  const option = window.confirm(description)
    ? options.find(({ style }) => style !== 'cancel')
    : options.find(({ style }) => style === 'cancel')

  if (option && option.onPress) {
    option.onPress()
  }
}

export default Platform.OS === 'web'
  ? { alert: alertWeb }
  : Alert
