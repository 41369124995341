import React, { useState, useEffect } from 'react'
import { View, Text, TextInput, StyleSheet } from 'react-native'
import uuid from 'uuid/v4'
import Container from '../components/Container'
import Loading from '../components/Loading'
import Card from '../components/Card'
import Button from '../components/Button'
import api, { ApiError } from '../api'
import { colors } from '../theme'

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default function Login({ navigation }) {
  // Inputs
  const [emailAddress, setEmailAddress] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  useEffect(() => {
    const fullName = emailAddress.match(/^(\w+)\.(\w+)@/)
    if (fullName) {
      if (!firstName) setFirstName(capitalize(fullName[1]))
      if (!lastName) setLastName(capitalize(fullName[2]))
    }
  }, [emailAddress])

  // Fetching
  const [fetching, setFetching] = useState(false)

  async function handlePress() {
    setFetching(true)
    const phoneId = uuid()
    try {
      await api.register(emailAddress, phoneId)
      navigation.navigate('Otp', {
        emailAddress,
        firstName,
        lastName,
        phoneId,
      })
    } catch (e) {
      if (e instanceof ApiError && e.type === 'unauthorized_domain') {
        alert(`Seuls les noms de domaine suivants sont acceptés : ${e.domains.join(', ')}.`)
      } else {
        // TODO? - separate error for invalid address
        alert('Une erreur inconnue s\'est produite, veuillez vérifier votre adresse et votre connexion à internet.')
      }
    }
    setFetching(false)
  }

  // TODO? - refactor: custom TextInput component?
  return (
    <Container centered>
      <Loading loaded={!fetching}>
        <Card title="Connexion">
          <View style={styles.input}>
            <Text style={styles.inputLabel}>E-mail</Text>
            <TextInput
              style={styles.inputEntry}
              value={emailAddress.trim()}
              onChangeText={setEmailAddress}
              autoFocus
              autoCapitalize="none"
              autoCompleteType="email"
              textContentType="emailAddress"
              keyboardType="email-address"
            />
          </View>
          <View style={styles.input}>
            <Text style={styles.inputLabel}>Prénom</Text>
            <TextInput
              style={styles.inputEntry}
              value={firstName}
              onChangeText={setFirstName}
              autoCompleteType="name"
              textContentType="name"
            />
          </View>
          <View style={styles.input}>
            <Text style={styles.inputLabel}>Nom</Text>
            <TextInput
              style={styles.inputEntry}
              value={lastName}
              onChangeText={setLastName}
              autoCompleteType="name"
            />
          </View>
          <Button onPress={handlePress} disabled={!emailAddress || !firstName || !lastName}>
            Se connecter
          </Button>
          <Button secondary onPress={() => navigation.goBack()}>
            Retour
          </Button>
        </Card>
      </Loading>
    </Container>
  )
}

const styles = StyleSheet.create({
  input: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputLabel: {
    flex: 1,
    fontWeight: 'bold',
  },
  inputEntry: {
    flex: 3,
    height: 35,
    borderBottomWidth: 1,
    borderBottomColor: colors.secondary,
  },
})
