import React, { useContext } from 'react'
import { ScrollView, Text, StyleSheet } from 'react-native'
import UserContext from '../UserContext'
import Container from '../components/Container'
import Card from '../components/Card'
import Button from '../components/Button'
import { colors } from '../theme'

function FolktraceAddress() {
  return <EmailAddress>folktrace@unamur.be</EmailAddress>
}

function EmailAddress({ children }) {
  // TODO - open a mail app when pressed
  return <Text style={styles.highlight}>{children}</Text>
}

export default function About({ navigation }) {
  const user = useContext(UserContext)

  const toContract = () => {
    navigation.navigate('Contract', { approvable: false })
  }

  return (
    <Container>
      <ScrollView>
        <Card title="À propos">
          <Text>
            Folktrace est une application mobile et web qui a pour but d'assurer 
            le suivi des accès au sein de l'animation étudiante de l'UNamur.
          </Text>
        </Card>
        <Card title="Contact">
          <Text>
            Pour une question relative à cette application, veuillez contacter <FolktraceAddress />.
          </Text>
        </Card>
        {user.uuid.length > 0 && <Card title="Vos données">
          <Text>
            En vous connectant à Folktrace, vous avez accepté le{' '}
            <Text onPress={toContract} style={styles.highlight}>
              contrat d'utilisation
            </Text>
            .
            {'\n\n'}
            Si vous souhaitez obtenir une copie de vos données, introduire une demande
            de suppression ou une demande de rectification, veuillez prendre contact à
            l'adresse suivante : <FolktraceAddress />.
          </Text>
        </Card>}
      </ScrollView>
      <Button secondary onPress={() => navigation.goBack()}>
        Retour
      </Button>
    </Container>
  )
}

const styles = StyleSheet.create({
  highlight: {
    color: colors.primary,
  },
})
