import React, { useState, useEffect } from 'react'
import { Platform } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import AsyncStorage from '@react-native-community/async-storage'
import Home from './screens/Home'
import Login from './screens/Login'
import Otp from './screens/Otp'
import Scanner from './screens/Scanner'
import About from './screens/About'
import Contract from './screens/Contract'
import UserContext, { initialUser } from './UserContext'

const Stack = createStackNavigator()

export default () => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    AsyncStorage
      .getItem('user')
      .then(json => setUser(json
        ? JSON.parse(json)
        : initialUser))
      .catch(console.error)
  }, [])

  if (!user) {
    // Render nothing until user is actually loaded
    // We could render an error message if it fails, but it wont fail... right?
    return null
  }

  // We could pass `value={{ ...user, update }}` to the context provider but, as
  // it would create a new object on every render, it would provoke unnecessary
  // renders (see https://reactjs.org/docs/context.html#caveats).
  //
  // To avoid that we add the function directly to the state. It wont provoke a
  // new render each time but only when the user reference changes.
  user.update = updates => {
    const data = { ...user, ...updates }
    setUser(data)
    AsyncStorage
      .setItem('user', JSON.stringify(data))
      .catch(console.error)
  }

  return (
    <UserContext.Provider value={user}>
      <NavigationContainer>
        <Stack.Navigator
          initialRouteName="Home"
          screenOptions={{ headerShown: false }}
        >
          <Stack.Screen
            name="Home"
            component={Home}
            options={{ title: 'Folktrace | Accueil' }}
          />
          <Stack.Screen
            name="Contract"
            component={Contract}
            options={{ title: 'Folktrace | Contrat d\'utilisation' }}
          />
          <Stack.Screen
            name="Login"
            component={Login}
            options={{ title: 'Folktrace | Connexion' }}
          />
          <Stack.Screen
            name="Otp"
            component={Otp}
            options={{ title: 'Folktrace | Connexion' }}
          />
          <Stack.Screen
            name="About"
            component={About}
            options={{ title: 'Folktrace | À propos' }}
          />
          {Platform.OS !== 'web' && <Stack.Screen
            name="Scanner"
            component={Scanner}
          />}
        </Stack.Navigator>
      </NavigationContainer>
    </UserContext.Provider>
  )
}
