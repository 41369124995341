import React, { useState, useEffect } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import Card from '../components/Card'
import Loading from '../components/Loading'
import Button from '../components/Button'
import api from '../api'
import { colors } from '../theme'

export default function Affluence() {
  const [sites, setSites] = useState({ data: [], loaded: false, success: false })
  const [refreshable, setRefreshable] = useState(false)

  useEffect(() => {
    refreshSites()
  }, [])

  useEffect(() => {
    if (sites.loaded) {
      const delay = sites.success ? 60000 : 3000
      const timeout = setTimeout(() => setRefreshable(true), delay)
      return () => clearTimeout(timeout)
    }
  }, [sites])

  async function refreshSites() {
    setSites({ ...sites, loaded: false })
    setRefreshable(false)
    try {
      const data = await api.affluence()
      data.sort((a, b) => a.name.localeCompare(b.name))
      setSites({ data, loaded: true, success: true })
    } catch {
      setSites({ ...sites, loaded: true, success: false })
    }
  }

  return (
    <Card title="Affluence">
      <Loading loaded={sites.loaded}>
        {sites.data.map(site =>
          <View key={site.name} style={styles.item}>
            <Text style={styles.itemText}>{site.name}</Text>
            <Text style={styles.itemText}>{site.current}/{site.capacity}</Text>
          </View>
        )}
        {
          sites.success
            ? sites.data.length === 0 && <Text>Aucun site n'est accessible.</Text>
            : <Text>Une erreur s'est produite. Veuillez vérifier votre connexion à internet avant de réessayer.</Text>
        }
        {refreshable && <Button secondary onPress={refreshSites}>
          Rafraîchir
        </Button>}
      </Loading>
    </Card>
  )
}

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: colors.secondary,
    borderBottomWidth: 0.5,
    marginBottom: 5,
  },
  itemText: {
    fontSize: 15,
  },
})
