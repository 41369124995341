import React, { useState, useEffect, useContext } from 'react'
import { View, Text, StyleSheet, Alert } from 'react-native'
import { BarCodeScanner } from 'expo-barcode-scanner'
import UserContext from '../UserContext'
import Container from '../components/Container'
import Card from '../components/Card'
import Button from '../components/Button'
import api, { ApiError } from '../api'

const validQr = /^(u|s):[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export default function ({ navigation }) {
  // Permission
  const [askingPermission, setAskingPermission] = useState(true)
  const [hasPermission, setHasPermission] = useState(false)

  useEffect(() => {
    if (askingPermission) {
      BarCodeScanner.requestPermissionsAsync().then(({ status }) => {
        setAskingPermission(false)
        setHasPermission(status === 'granted')
      })
    }
  }, [askingPermission])

  // Scanner
  const user = useContext(UserContext)
  const [allowScan, setAllowScan] = useState(false)
  const [scanning, setScanning] = useState(false)

  useEffect(() => {
    // Give a 2 seconds delay to avoid unwanted scans
    if (scanning) {
      setAllowScan(false)
    } else {
      const timeout = setTimeout(() => setAllowScan(true), 2000);
      return () => clearTimeout(timeout)
    }
  }, [scanning])

  async function handleScan({ data }) {
    setScanning(true)

    let message
    try {
      const match = data.match(validQr)
      if (!match) {
        throw new ApiError('bad_uuid')
      }

      const type = match[1] === 'u' ? 'user' : 'site'
      const response = await api.entrance(user.token, type, data.substring(2))
      message = `${response === 'entrance' ? 'Entrée' : 'Sortie'} enregistrée.`
    } catch (e) {
      if (e instanceof ApiError && e.type === 'not_in_site') {
        message = 'Vous n\'êtes présent dans aucun site. Veuillez d\'abord vous faire scanner, ou scanner le code QR du site en question.'
      } else if (e instanceof ApiError && e.type === 'bad_uuid') {
        message = 'Ce code QR n\'est pas valide.'
      } else {
        message = 'Une erreur inconnue s\'est produite, veuillez vérifier votre connexion à internet.'
      }
    }

    // We can use `Alert.alert` because this screen can't be reached on the web version.
    Alert.alert(
      'Scan',
      message,
      [
        { text: 'Fermer', style: 'cancel', onPress: () => navigation.goBack() },
        { text: 'Continuer', style: 'default', onPress: () => setScanning(false) },
      ],
      { cancelable: false }
    )
  }

  if (hasPermission) {
    return (
      <Container>
        <BarCodeScanner
          style={StyleSheet.absoluteFillObject}
          barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
          onBarCodeScanned={allowScan ? handleScan : undefined}
        />
        <View style={styles.bottom}>
          <Button secondary onPress={() => navigation.goBack()}>
            Retour
          </Button>
        </View>
      </Container>
    )
  } else {
    return (
      <Container centered>
        <Card title="Scanner">
          <Text>
            Folktrace a besoin de votre permission pour utiliser la caméra.
          </Text>
          <Button disabled={askingPermission} onPress={() => setAskingPermission(true)}>
            Accorder
          </Button>
          <Button secondary onPress={() => navigation.goBack()}>
            Retour
          </Button>
        </Card>
      </Container>
    )
  }
}

const styles = StyleSheet.create({
  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 5,
  },
})
