import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { colors } from '../theme'

export default function Card({ title, children }) {
  return (
    <>
      {title && <Text style={styles.title}>{title}</Text>}
      <View style={styles.card}>
        {children}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: colors.foreground,
    borderRadius: 4,
    marginBottom: 20,
    padding: 15,
  },
  title: {
    fontSize: 30,
    fontWeight: '100',
  },
})
