import React from 'react'
import { View, StyleSheet } from 'react-native'
import Constants from 'expo-constants'
import { colors } from '../theme'

export default function Container({ centered, children }) {
  return (
    <View style={[styles.container, centered && styles.centered]}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    flex: 1,
    width: '100%',
    maxWidth: 500,
    marginLeft: 'auto', // marginHorizontal: 'auto' doesn't work on Android (?)
    marginRight: 'auto',
    marginTop: Constants.statusBarHeight,
    padding: 15,
  },
  centered: {
    justifyContent: 'center',
  },
})
